
import { defineComponent, PropType } from 'vue';

interface PropertyListItem {
  title: string;
  text: string;
  icon: string;
}

export default defineComponent({
  name: 'PropertyList',
  props: {
    items: Array as PropType<PropertyListItem[]>,
  },
  computed: {
    itemsPerColumn(): number {
      if (!this.items) {
        return 0;
      }

      return Math.ceil(this.items.length / 2);
    },
    columns(): [Array<PropertyListItem>, Array<PropertyListItem>] {
      const initial: [Array<PropertyListItem>, Array<PropertyListItem>] = [[], []];

      if (!this.items) {
        return initial;
      }

      // separate the items into 2 columns
      return this.items.reduce((acc, item: PropertyListItem, i: number) => {
        acc[i < this.itemsPerColumn ? 0 : 1].push(item);
        return acc;
      }, initial);
    },
  },
});
