
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VideoThumb',
  props: {
    thumbnail: String,
    video: String,
  },
});
