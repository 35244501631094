
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SocialIcons',
  data() {
    return {
      socials: [
        {
          title: 'Facebook',
          icon: 'facebook',
          url: 'https://de-de.facebook.com/FIREGROUPcom',
        },
        {
          title: 'Twitter',
          icon: 'twitter',
          url: 'https://twitter.com/firegroupcom',
        },
        {
          title: 'YouTube',
          icon: 'youtube',
          url: 'https://www.youtube.com/c/Firegroup',
        },
        {
          title: 'Instagram',
          icon: 'instagram',
          url: 'https://www.instagram.com/team.firegroup/',
        },
        {
          title: 'LinkedIn',
          icon: 'linkedin',
          url: 'https://www.linkedin.com/company/firegroup-gmbh',
        },
      ],
    };
  },
});
