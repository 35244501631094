
import { defineComponent } from 'vue';
import PropertyList from '@/components/PropertyList.vue';
import ProductImages from '@/components/ProductImages.vue';

export default defineComponent({
  name: 'Home',
  components: {
    ProductImages,
    PropertyList,
  },
  computed: {
    slideshowImages(): string[] {
      if (!this.images) {
        return [];
      }

      return this.images.slice(0, 3);
    },
  },
  data() {
    return {
      images: [
        require('@/assets/products/piaget-altiplano-ultimate-910p-1.png'),
        require('@/assets/products/piaget-altiplano-ultimate-910p-2.png'),
        require('@/assets/products/piaget-altiplano-ultimate-910p-3.png'),
        require('@/assets/products/piaget-altiplano-ultimate-910p-4.png'),
        require('@/assets/products/piaget-altiplano-ultimate-910p-5.png'),
        require('@/assets/products/piaget-altiplano-ultimate-910p-6.png'),
      ],
      detailItems: [
        {
          title: 'Brand',
          text: 'Piaget',
          icon: 'strap',
        },
        {
          title: 'Collection',
          text: '60th Anniversary',
          icon: 'cogs',
        },
        {
          title: 'Movement',
          text: 'Automatic Movement',
          icon: 'watch-front',
        },
        {
          title: 'Warranty',
          text: '2 years',
          icon: 'watch-front-2',
        },
        {
          title: 'Gender',
          text: 'For Men',
          icon: 'watch-inside',
        },
        {
          title: 'Origin',
          text: 'Switzerland',
          icon: 'watch-wind',
        },
      ],
      specItems: [
        {
          title: 'Metal',
          text: 'Rose Gold / Silver',
          icon: 'strap',
        },
        {
          title: 'Metal Weight',
          text: '32.14',
          icon: 'cogs',
        },
        {
          title: 'Strap Type',
          text: 'Alligator',
          icon: 'watch-front',
        },
        {
          title: 'Strap Color',
          text: 'Black / Brown',
          icon: 'watch-back',
        },
        {
          title: 'Case Shape',
          text: 'Round',
          icon: 'watch-front-2',
        },
        {
          title: 'Case Diameter',
          text: '38 mm',
          icon: 'watch-inside',
        },
        {
          title: 'Case Thickness',
          text: '3.45 mm',
          icon: 'watch-wind',
        },
        {
          title: 'Water Resistance',
          text: '30 m / 02 ATM',
          icon: 'details',
        },
      ],
      relatedProducts: [
        {
          brand: 'Piaget',
          name: 'Altiplano',
          image: require('@/assets/products/GOA39112/main.png'),
          price: 24900,
          serial: 'Watch GOA39112',
        },
        {
          brand: 'Piaget',
          name: 'Altiplano',
          image: require('@/assets/products/GOA39113/main.png'),
          price: 24900,
          serial: 'Watch GOA39113',
        },
        {
          brand: 'Piaget',
          name: 'Altiplano',
          image: require('@/assets/products/GOA39114/main.png'),
          price: 24900,
          serial: 'Watch GOA39114',
        },
        {
          brand: 'Piaget',
          name: 'Altiplano',
          image: require('@/assets/products/GOA39112/main.png'),
          price: 24900,
          serial: 'Watch GOA39115',
        },
        {
          brand: 'Piaget',
          name: 'Altiplano',
          image: require('@/assets/products/GOA39113/main.png'),
          price: 24900,
          serial: 'Watch GOA39116',
        },
        {
          brand: 'Piaget',
          name: 'Altiplano',
          image: require('@/assets/products/GOA39114/main.png'),
          price: 24900,
          serial: 'Watch GOA39117',
        },
        {
          brand: 'Piaget',
          name: 'Altiplano',
          image: require('@/assets/products/GOA39112/main.png'),
          price: 24900,
          serial: 'Watch GOA39118',
        },
        {
          brand: 'Piaget',
          name: 'Altiplano',
          image: require('@/assets/products/GOA39113/main.png'),
          price: 24900,
          serial: 'Watch GOA39119',
        },
        {
          brand: 'Piaget',
          name: 'Altiplano',
          image: require('@/assets/products/GOA39114/main.png'),
          price: 24900,
          serial: 'Watch GOA39120',
        },
      ],
    };
  },
});
