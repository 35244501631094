
import { defineComponent } from 'vue';
import SocialIcons from '@/components/SocialIcons.vue';

export default defineComponent({
  name: 'AppFooter',
  components: {
    SocialIcons,
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
});
