
import MainMenu from '@/components/MainMenu.vue';
import MainMenuOverlay from '@/components/MainMenuOverlay.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  components: {
    MainMenu,
    MainMenuOverlay,
    AppFooter,
  },
};
