
import { defineComponent } from 'vue';
import MainMenu from '@/components/MainMenu.vue';

export default defineComponent({
  name: 'MainMenuOverlay',
  components: {
    MainMenu,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    toggle(force: boolean) {
      if (undefined === force) {
        force = !this.isVisible;
      }

      this.isVisible = force;
    },
  },
});
